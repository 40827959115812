import dagreD3 from 'dagre-d3';
import * as d3 from "d3";

const addNodes = (g, nodes) => {
  nodes.forEach((node, _index) => {
    let nodeId = '';
    let className = '';
    
    if (node.type === "step") {
      nodeId = `step_${node.id}`;
      className = 'type-STEP'
    } else {
      nodeId = `action_${node.id}`;
      className = 'type-ACTION';
    }
    
    g.setNode(
      nodeId,
      {
        label: node.name,
        class: className,
        detail: node.detail,
        type: node.type,
        url: node.url
      });
  });
};

const addEdges = (g, edges) => {
  edges.forEach((edge, _index) => {
    g.setEdge(edge.source, edge.target);
  });
};

// For dynamic tipso content
const populateNodeContent = (node) => `<div>${node.detail}</div>`;

const drawChart = (data) => {
  if (data.nodes === undefined) return;
  
  let g = new dagreD3.graphlib.Graph()
  .setGraph({})
  .setDefaultEdgeLabel(function () { return {}; });
  
  addNodes(g, data.nodes);
  addEdges(g, data.edges);
  
  // Create the renderer
  let render = new dagreD3.render();
  
  // Set up an SVG group so that we can translate the final graph.
  let svg = d3.select("svg"),
    inner = d3.select("svg g"),
    zoom = d3.zoom().on("zoom", function () {
      inner.attr("transform", d3.event.transform);
    }),
    svgGroup = svg.append("g");
  
  svg.call(zoom);
  
  // Run the renderer. This is what draws the final graph.
  render(inner, g);
  
  inner.selectAll("g.node").each(function (v) {
    let rect = this.getBoundingClientRect();
    let node = g.node(v);
    
    $(this).tipso({
      size: 'small',
      titleContent: node.label,
      showArrow: true,
      position: 'right',
      offsetX: rect.width,
      offsetY: rect.height / 2,
      content: populateNodeContent(node)
    });
    
    $(this).on("click", function() {
      window.location.href = node.url;
    });
  });
  
  // Center the graph
  let xCenterOffset = (svg.attr("width") - g.graph().width) / 2;
  svgGroup.attr("transform", "translate(" + xCenterOffset + ", 20)");
  svg.attr("height", g.graph().height + 40);
};

const populateVisualization = (scenarioId) => {
  jQuery.ajax({
    type: "GET",
    url: `/admin/scenarios/${scenarioId}/visual_map`,
    beforeSend: () => $("#spinner").show(),
    success: (data) => {
      drawChart(data);
      $("#spinner").hide();
    },
    error: () => console.log('error')
  });
};

document.addEventListener('turbolinks:load', () => {
  if (document.querySelectorAll(".scenario-visualization.my-2").length > 0) {
    let element = document.getElementById('scenarioId');
    let scenarioId = element.dataset.scenarioId;
    
    populateVisualization(scenarioId);
  }
});


