import Rails from "@rails/ujs";
import ClipboardJS from "clipboard";
import { admin_conversation_messages_path } from "routes";

document.addEventListener("turbolinks:load", () => {
  setTimeout(() => {
    const messagesContainer = $(".conversation-messages-container");

    if (messagesContainer.length) {
      messagesContainer.stop().animate({
        scrollTop: messagesContainer[0].scrollHeight
      });
    }
  }, 100);

  $("#message_content").on("keypress", (e) => {
    if (e.which === 13) {
      Rails.fire($("form#new_message").get(0), "submit");
    }
  });

  const clipboard = new ClipboardJS("#btn-copy");
  const toastOptions = {
    position: "top-right",
    showHideTransition: "fade",
    loader: false,
    allowToastClose: false
  };

  clipboard.on("success", function (e) {
    $.toast({
      text: "Copied",
      bgColor: "#0acf97",
      ...toastOptions
    });
    e.clearSelection();
  });

  clipboard.on("error", function (e) {
    $.toast({
      text: "Copy không thành công",
      bgColor: "#fa5c7c",
      ...toastOptions
    });
  });

  $("#suggestions").on("click", "[id^=suggest-answer]", (e) => {
    const formData = new FormData();
    formData.append("text_message[content]", e.target.dataset.content);

    Rails.ajax({
      type: "POST",
      url: admin_conversation_messages_path(e.delegateTarget.dataset.conversationId),
      data: formData
    });
  });
});
