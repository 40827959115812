import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "menuItem"];

  search() {
    const searchText = this.inputTarget.value.toLowerCase();

    this.menuItemTargets.forEach((item) => {
      const menuItemText = item.textContent.toLowerCase();
      if (menuItemText.includes(searchText)) {
        item.classList.remove('d-none');
      } else {
        item.classList.add('d-none');
      }
    });
  }
}
