import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["signedIdField", "thumbnail"]

  deleteOldImage(e) {
    e.preventDefault()
    if (!this.hasSignedIdFieldTarget) { return }

    this.thumbnailTarget.style.visibility = "hidden"
    this.signedIdFieldTarget.disabled = true
  }
}
