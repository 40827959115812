document.addEventListener("turbolinks:load", () => {
  $("[id$=step_action_id]").select2();

  $(".step-form").on("cocoon:after-insert", function (e, insertedItem, originalEvent) {
    $(insertedItem).find("[id$=step_action_id]").select2();
  });
  $('#scenario_packages').select2();
  $('#scenario_auditors').select2();
  $('#scenario_sources').select2();
});
