import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["babyDelivered", "ageUnit"]

  connect() {
    this.setAgeUnit(this.babyDeliveredTarget.checked)
  }

  toggleAgeUnit(event) {
    this.setAgeUnit(event.currentTarget.checked)
  }

  setAgeUnit(checked) {
    this.ageUnitTargets.forEach((unit) => {
      unit.textContent = checked ? "Month" : "Week"
    })
  }
}
