let toggleSendTimeVisibility = (checked) => {
  if(checked) {
    $('.reminder_template_send_at').show();
    
    let date = new Date();
    if ($('#reminder_template_send_at_1i').val() === '') {
      $('#reminder_template_send_at_1i').val(date.getFullYear());
      $('#reminder_template_send_at_2i').val(date.getMonth());
      $('#reminder_template_send_at_3i').val(date.getDay());
      $('#reminder_template_send_at_4i').val('00');
      $('#reminder_template_send_at_5i').val('00');
    }
  } else {
    $('.reminder_template_send_at').hide();
    $('#reminder_template_send_at_1i').val(null);
    $('#reminder_template_send_at_2i').val(null);
    $('#reminder_template_send_at_3i').val(null);
    $('#reminder_template_send_at_4i').val(null);
    $('#reminder_template_send_at_5i').val(null);
  }
};

document.addEventListener('turbolinks:load', () => {
  let setFixedTimeInputCtrl = $('#reminder_template_set_fixed_delivery_time');
  toggleSendTimeVisibility(setFixedTimeInputCtrl.prop('checked'));
  
  setFixedTimeInputCtrl.on('change', (event) => {
    let checked = $(event.target).prop('checked');
    toggleSendTimeVisibility(checked);
  });
});
