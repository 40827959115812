require('jquery-slimscroll');
require('daterangepicker');
require('metismenu');
require('select2');
require('tipso');
require('hyper/js/app.min');

import 'hyper/css/icons.min.css';
import 'hyper/css/app.min.css';

document.addEventListener('turbolinks:load', () => {
  $.App.init();
});
