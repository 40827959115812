import './hyper';
import './fileInput';
import './common';
import '../libs/cocoon';
import '../libs/bootstrap-datepicker';
import './pages/knowledge';
import './pages/promotion';
import './pages/conversation';
import './pages/scenarioVisualization';
import './pages/reminderTemplate';
import './pages/adminUser';
import './pages/user';
import './pages/package';
import './pages/voucher';
import './pages/scenario';
import './pages/scenarioGroup';
import './pages/appReminderTemplate';
import './pages/searchTerm';
import './pages/dashboard';

import '../stylesheets/admin.scss';
import 'tipso/src/tipso.min.css';
