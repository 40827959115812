require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('bootstrap')

window.jQuery = $;
window.$ = $;

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

import 'chartkick/chart.js';
import '../admin';
import '../controllers';
