import { admin_users_path } from "routes";

document.addEventListener('turbolinks:load', () => {
  $('#user_package_ids').select2();

  const $userSelect2 = $('.js-user-select2');
  $userSelect2.select2({
    placeholder: $userSelect2.attr('placeholder'),
    ajax: {
      url: admin_users_path({ format: 'json' }),
      dataType: 'json',
      data: function (params) {
        return ({
          q: {
            first_name_or_last_name_or_email_or_code_cont: params.term,
          },
          type: $userSelect2.data('type'),
          page: params.page || 1
        });
      }
    }
  });
});
