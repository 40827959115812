document.addEventListener('turbolinks:load', () => {
  const knowledgeForm = $('form.knowledge-form');

  knowledgeForm.on('change', '.knowledge-delivered-checkbox', (event) => {
    const target = $(event.target);
    const babyAgeUnitInput = knowledgeForm.find('.knowledge-baby-age-unit-hidden-input')
    const babyAgeUnitSelect = knowledgeForm.find('.knowledge-baby-age-unit-select');

    const checked = target.prop('checked');
    const nextValue = checked ? 'months' : 'weeks';

    babyAgeUnitInput.val(nextValue);
    babyAgeUnitSelect.val(nextValue);
  });

  knowledgeForm.on('change', '.rich-text-content-checkbox', (event) => {
    const target = $(event.target);
    const checked = target.prop('checked');

    const paragraphFieldsWrapper = target.parents('.paragraph-fields');
    const normalTextContentWrapper = paragraphFieldsWrapper.find('.normal-text-content-wrapper');
    const richTextContentWrapper = paragraphFieldsWrapper.find('.rich-text-content-wrapper');

    if (checked) {
      normalTextContentWrapper.addClass('d-none');
      richTextContentWrapper.removeClass('d-none');
    } else {
      normalTextContentWrapper.removeClass('d-none');
      richTextContentWrapper.addClass('d-none');
    }
  });
});
